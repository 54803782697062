/* eslint-disable @typescript-eslint/no-explicit-any */
import './RoomCard.scss';

/* eslint-disable-next-line */
export interface RoomCardProps {
  data: any;
}

export function RoomCard(props: RoomCardProps) {
  const { data } = props;
  return (
    <div className="card roomCard">
      <div className="card-body">
        {/* <label className="fontsize-10 fw600 color-600 mb-2 d-block">
          Room {data?.noOfRooms}
        </label> */}
        <h5 className="mb-2 fw600">{data?.roomTypeId?.name}</h5>
        <div className="row gutters-5 justify-content-between align-items-center">
          <div className="col-auto">
            <label className='mb-0 fontsize-12 fw500 color-700'>${Number(data?.thirdPartyMerchantPrice ?? data?.price)?.toFixed(2)}</label>
          </div>
          <div className="col-auto">
            <label className='mb-0 _room fontsize-12 fw500'>{data?.noOfRooms} Rooms</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoomCard;
